import React from "react";
import { useParams } from "react-router-dom";
import { ProjList } from "../helpers/ProjList";
import GitHubIcon from '@material-ui/icons/GitHub';
import "../styles/ProjectDisplay.css";

function ProjectDisplay() {
    const { id } = useParams();
    const project = ProjList[id];
    
    return (
      <div className="project" style={{ textAlign: 'center' }}> {/* Center the entire content */}
        <h1>{project.name}</h1>
  
        {/* Center the main image */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={project.image} style={{ width: '600px', height: 'auto' }} />
        </div>
  
        {/* Text content made narrower and centered */}
        <div style={{ maxWidth: '900px', margin: '0 auto', textAlign: 'left' }}>
          <p><b>Skills:</b> {project.skills}</p>
          <p><b>Development:</b> {project.process}</p>
          <p><b>About:</b> {project.description}</p>
          
          <p>{project.feature1}</p>
        </div>
  
        {/* Center the additional images */}
        <div style={{ maxWidth: '900px', margin: '0 auto', textAlign: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={project.image1} style={{ width: '600px', height: 'auto' }} />
          </div>
          <p>{project.feature2}</p>
          
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={project.image2} style={{ width: '600px', height: 'auto' }} />
          </div>
          <p>{project.feature3}</p>
          
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={project.image3} style={{ width: '600px', height: 'auto' }} />
          </div>
          <p>{project.feature4}</p>
          
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={project.image4} style={{ width: '600px', height: 'auto' }} />
          </div>
          <p>{project.feature5}</p>
          
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={project.image5} style={{ width: '600px', height: 'auto' }} />
          </div>
          <p>{project.feature6}</p>
          
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={project.image6} style={{ width: '600px', height: 'auto' }} />
          </div>
          <p>{project.feature7}</p>
          
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={project.image7} style={{ width: '600px', height: 'auto' }} />
          </div>
        </div>
  
        <a href={project.link} target="_blank" rel="noopener noreferrer">
          <GitHubIcon />
        </a>
        <p>Click this icon to go to the repository</p>
      </div>
    );
  }

export default ProjectDisplay;