import Proj1 from "../assets/Tasks View.png"
import Home from "../assets/Home.png"
import Tasks from "../assets/Project Detail View.png"
import Invite from "../assets/Invite users modal.png"
import Login from "../assets/Login.png"
import Site from "../assets/WebsiteHome.png"
import Mobile from "../assets/WebsiteMobile.png"
import Clust1 from "../assets/Cluster1Blur.jpeg"
import Clust2 from "../assets/Cluster2Blur.jpeg"
import GroupView from "../assets/GroupViewBlur.jpeg"
import PortalHome from "../assets/HomePage.png"
import JobOverview from "../assets/JobOverview.png"
import JobStats from "../assets/JobStats.png"
import Support from "../assets/SupportPage.png"
import Falvey from "../assets/FTFHome.png"

import { GitHub } from "@material-ui/icons"
export const ProjList = [
    {
        name:"NovaManage - A project management app",
        CoverImage: Home,
        image:Proj1,
        skills:"JavaScript, React, React Redux, MongoDB, MaterialUI, Java, JWT, Express",
        process:"This was created by myself and two other students - Daniel Frost & Mark Faverzani at Villanova University.",
        description:"NovaManage is a simple yet efficient tool used to help manage projects and tasks of varying levels.",
        feature1: "Calendar-based planning: The home page displays a calendar that shows all necessary tasks that need to be done in the given month on their set due date.",
        image1:Home,
        feature2: "Customizable project page: Upon entering the project section, the user is able to create new projects as well as certain tasks with set due dates inside that project, and move them around based on priority, or however the user wishes.",
        image2:Tasks,
        feature3: "Real-time collaboration: Users are able to share tasks/projects with other users. Once shared, the other user can add/edit the project page that they were invited to in real time.",
        image3: Invite,
        feature4: "Signup/login: User's data is stored on their account made when first using the app. Unique accounts make the real-time collaboration possible.",
        image4: Login,
        link: "https://github.com/DanF10/2053FinalProject/tree/main/final-home" 
        // icon: <GitHubIcon />,

    },
    {
       
        name:"Personal Website",
        CoverImage: Site,
        image:Site,
        skills:"JavaScript, React, MaterialUI, Hostinger, React Router Dom, Vertical Timeline",
        process:"This site was developed completely by myself, and deployed using Hostinger.",
        description:"This website serves as a more in-depth resume that better shows my projects, experience, and technical skills.",
        feature1:"One of the unique features on this website is a navbar that adjusts based size of screen. This helps accomodate for users on a mobile device rather than on a PC.",
        image1: Mobile,
        feature2:"Other features include an animated timeline using react libraries that can be seen on the experience page, and the usage of materialUI throughout the website to add icons.",
        feature3:"In the future, I hope to further expand this website with projects and more experience",
        feature4:"",
        link: "https://github.com/lukefranc0/PersonalSite" ,
        // icon: <GitHubIcon />,
    },
    {
        name: "Yale Center for Research Computing - User Portal",
        CoverImage: PortalHome,
        image: PortalHome,
        skills:"Python, Linux, Flask, Dash, High Performance Computing Clusters, LDAP3, HTML, CSS",
        process: "In the Summer of 2024, I worked as a High Performance Computing intern at Yale Center for Research Computing. I developed the multi-page site by myself with guidance from my mentor, Tom Langford, at YCRC.",
        description: "This application is a multi-page user portal for hundreds of HPC cluster users at Yale to facilitate in accessing real time job infromation, account details, and cluster usage statistics. This was all coded in Python using Flask and Dash as frameworks, and I utilized LDAP3 to retrieve user information. It is currently live for all Yale researches who have access to cluster usage.",
        feature1:"The Home page of the User Portal is a general dashboard that gives users a welcoming introduction to the Portal showing information such as recent jobs, file system quotas and monthly update messages.",
        image1: PortalHome,
        feature2:"The Group View page of the Portal shows the user all of their Linux and Slurm groups that they are a part of. In the picture shown, I was a part of only one Linux group and one Slurm group. Each table shows names, ID's, and Yale emails of each member.",
        image2: GroupView,
        feature3: "The Cluster Usage page shows researches a detailed breakdown of their group's cluster usage over the last month. Below the two tables that show usage in CPU-hours, there is an interactive graph that shows cluster usage. There are two options to filter the usage by: partition and user. These show two different views of cluster usage among your group at Yale.",
        image3: Clust2,
        image4: Clust1,
        feature5: "The Job Overview page shows a table with detailed information about jobs. There is a selector that researchers can use to change the range of dates of submitted jobs. For example, users can see all of their submitted jobs in the last 2 weeks, 1 week, or 24 hours. In each row is a clickable link that brings the user to the JobStats page which is a detailed breakdown of job statistics.",
        image5: JobOverview,
        feature6: "The JobStats page uses a software made at Princeton University that gives an in depth look at statistics on a specific job as well as information that aids with showing the researcher how efficient their job was on both CPUs and GPUs",
        image6: JobStats
    },
    {
        name: "Falvey Table Finder Prototype",
        CoverImage: Falvey,
        image: Falvey,
        // image1: Falvey,
        skills: "ReactNative, Node.js, Firebase, JavaScript, QR Codes, Android Studio",
        process: "For a final project in my Software Engineering course, a team of myself and 2 others created a prototype ran on an android emulator",
        description: "The Falvey Table Finder is a prototype android app. The app enables users to check table availability at Falvey Library and mark tables as occupied by scanning unique QR codes, which are bound to individual tables. The system does not support reservations; instead, students can either see available tables in the app or go in person to claim a table by scanning its QR code. This approach aims to ease the challenge students face in finding available study tables during peak times like midterms and finals.",
        link: "https://github.com/markfav6/Falvey-Table-Finder"
    }
]