import React from 'react'
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
function Experience() {
    return (
        <div className="experience">
            <VerticalTimeline lineColor="#3e497a">
                {/* Newest to Oldest Order */}
                
                {/* Yale Center for Research Computing: May 2024 - Aug 2024 */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="May 2024 - Aug 2024"
                    iconStyle={{ background: "#e9d35b", color: "#fff" }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Yale Center for Research Computing
                    </h3>
                    <p>New Haven, CT</p>
                    <p>Interned full-time for 10 weeks with the Research Support team at the Yale Center for Research Computing, serving as a full-stack developer.</p>
                    <p>Developed a multi-page user portal app for hundreds of HPC cluster users at Yale to facilitate in accessing real time job information, account details, and cluster usage statistics.</p>
                    <p>Revamped Yale’s cluster status monitoring app to visualize interactive data of 200+ nodes and their usage metrics including CPU, GPU, and memory allocation sorted by partitions within each cluster.</p>
                </VerticalTimelineElement>

                {/* Fitness Center Attendant: Aug 2022 - Present */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="Aug 2022 - Present"
                    iconStyle={{ background: "#e9d35b", color: "#fff" }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Fitness Center Attendant
                    </h3>
                    <p>Villanova, PA</p>
                    <p>Oversaw and maintained a safe exercising environment at several gyms across campus during the academic semester.</p>
                </VerticalTimelineElement>

                {/* Villanova University: Aug 2021 - December 2024 (expected graduation) */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="Aug 2021 - December 2024 (expected graduation)"
                    iconStyle={{ background: "#3e497a", color: "#fff" }}
                    icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Villanova University
                    </h3>
                    <p>College of Liberal Arts and Sciences</p>
                    <p>B.S in Computer Science</p>
                </VerticalTimelineElement>

                {/* Blackwater Wealth Management: Jun 2022 - Aug 2022 */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="Jun 2022 - Aug 2022"
                    iconStyle={{ background: "#e9d35b", color: "#fff" }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Blackwater Wealth Management
                    </h3>
                    <p>North Haven, CT</p>
                    <p>Performed data entry-related tasks for 75+ clients.</p>
                    <p>Managed both individual and shared family accounts for clients and oversaw over 100 total accounts and profiles for my supervisor.</p>
                    <p>Used ClientWorks and WealthBox as the primary software.</p>
                </VerticalTimelineElement>

                {/* Dibella's Subs: Jun 2020 - Aug 2023 */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="Jun 2020 - Aug 2023"
                    iconStyle={{ background: "#e9d35b", color: "#fff" }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Dibella's Subs
                    </h3>
                    <p>Wallingford, CT</p>
                    <p>Worked as a crew member serving over 100 customers daily.</p>
                    <p>Prepared food, made deliveries, and took orders in a fast-paced, team-oriented environment.</p>
                </VerticalTimelineElement>

                {/* North Haven High School: Aug 2017 - Jun 2021 */}
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="Aug 2017 - Jun 2021"
                    iconStyle={{ background: "#3e497a", color: "#fff" }}
                    icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">
                        North Haven High School, North Haven, CT
                    </h3>
                    <p>High School Diploma</p>
                </VerticalTimelineElement>
                
            </VerticalTimeline>
        </div>
    );
}


export default Experience